import Service from '../Service';

const resource = "coolingchamber/"

export default {

    save(coc, requestID) {
        return Service.post(resource + 'save', coc, {
            params: { requestID }
        });
    },

    pagination(dtr, requestID) {
        console.log("Paginacion",dtr);
        return Service.post(resource + 'pagination', dtr, {
            params: { requestID }
        });
    },


    list(obj, requestID) {
        return Service.post(resource + 'list', obj, {
            params: { requestID }
        });
    },

    getPalletInCamera(obj, requestID) {
        return Service.post(resource + 'getPalletInCamera', obj, {
            params: { requestID }
        });
    },
    // para pdf
    PrfDistributionPalletTemperatureTunnelReport(ChaID, requestID) {
        return Service.post(resource + "PrfDistributionPalletTemperatureTunnelReport", {}, {
            params: { ChaID, requestID }
        });
    },

    PrfControlTemperatureChamberProductFinishReport(ChaID, requestID) {
        return Service.post(resource + "PrfControlTemperatureChamberProductFinishReport", {}, {
            params: { ChaID, requestID }
        });
    },

    prfcoolingchamberbatchreport(obj, requestID) {
        return Service.post(resource + "prfcoolingchamberbatchreport", obj, {
            params: { requestID }
        })
    },

    convertexcel(obj, requestID) {
        return Service.post(resource + "convertexcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    },

    converterpdf(obj, requestID) {
        return Service.post(resource + "converterpdf", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    }
}